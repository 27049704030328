import { CPost, CreatorData } from '@fg-types/creator';
import { ApiBase } from './api-base';
import { IPost } from '@fg-types/post';
import { HttpParams } from '@angular/common/http';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';
import { IConvention } from '@fg-types/convention.type';
import { Observable } from 'rxjs';
import { environment } from '@fg-environments/environment';

export class CreatorApi extends ApiBase {
  model = 'Creators';

  private postUrl = `${ environment.API_URL + environment.API_VERSION }/Posts`;
  private fanWallUrl = `${ environment.API_URL + environment.API_VERSION }/FanWalls`;
  private blockFunctionality = `${ environment.API_URL + environment.API_VERSION }/BlockByCreators`;
  private approvalFunctionality = `${ environment.API_URL + environment.API_VERSION }/ApproveByCreators`;
  private settings = `${ environment.API_URL + environment.API_VERSION }/creatorsettings`;

  newCreator(creator: CreatorData) {
    return this.http.post<CreatorData>(`${this.modelBase}`, creator);
  }
  getAllCreators() {
    return this.http.get<CreatorData[]>(`${this.modelBase}`);
  }
  getCreatorById(id) {
    return this.http.get<CreatorData>(`${this.modelBase}/${id}`);
  }
  updateCreatorById(creator: CreatorData) {
    return this.http.patch<CreatorData>(`${this.modelBase}/${creator.id}`, creator);
  }
  deleteCreatorById(id) {
    return this.http.delete<CreatorData>(`${this.modelBase}/${id}`);
  }
  getCreatorOrg(creator: CreatorData) {
    return this.http.get<CreatorData>(`${this.modelBase}/${creator?.id}/Organization`);
  }
  getCreatorPosts(creator: CreatorData, lastkey: string, type?: string) {
    // const params = new HttpParams();
    // if (lastKey) params.set('lastKey', lastKey ? JSON.stringify(lastKey) : null);
    const lastKey = lastkey ? JSON.stringify(lastkey) :  null;
    return this.http.get<PaginatedResponse<IPost>>(`${this.modelBase}/${creator?.id}/Posts`, {
      params : this.setParams({lastKey , type})
    });
  }
  searchCreatorsPost(query: string, creatorId?: string) {
    return this.http.get<PaginatedResponse<IPost>>(`${this.modelBase}/${creatorId}/searchPosts`, {
      params: this.setParams({ query, creatorId })
    });
  }
  getCreatorExhibitor(creator : CreatorData){
    return this.http.get<PaginatedResponse<ConventionExhibitor>>(`${this.modelBase}/${creator?.id}/Exhibitor`)
  }
  getCreatorConventions(creator: CreatorData, lastKey?: string, type?: string ){
    return this.http.get<PaginatedResponse<IConvention>>(`${this.modelBase}/${creator?.id}/Conventions`,{
    params: this.setParams({ lastKey, type })
    })
  }

  //FanWall Posts
  getFanWallPosts(creatorId: string, fanWallStatus?: string){
    return this.http.get<IPost>(`${this.fanWallUrl}/Creator/${creatorId}`, {
      params : {fanwallstatus: fanWallStatus}
    }); 
  }

  updateFanWallPosts(fanwallId: string,fanWallStatus?: string) {
    return this.http.request<any>('put',`${this.fanWallUrl}/${fanwallId}/action`,
    {
      params: {fanwallstatus : fanWallStatus}
    });
  }
  deleteFanWallRequest(fanwallId?: string){
    return this.http.delete<any>(`${this.fanWallUrl}/${fanwallId}`)
  }

  blockUsersForCreator(userId: string, creatorId: string) {
    return this.http.request<any>('put',`${this.blockFunctionality}/${userId}/block?creatorId=${creatorId}`);
  }
  
  unBlockUsersForCreator(userId: string, creatorId: string) {
    return this.http.request<any>('delete',`${this.blockFunctionality}/${userId}/block?creatorId=${creatorId}`);
  }

  getblockedUsersForCreator(creatorId: string) {
    return this.http.request<any>('get',`${this.blockFunctionality}/${creatorId}/blockedusers`);
  }

  getApprovedUsersForCreator(creatorId: string) {
    return this.http.request<any>('get',`${this.approvalFunctionality}/${creatorId}/approvedusers`);
  }

  approveUsersForCreator(userId: string, creatorId: string) {
    return this.http.request<any>('put',`${this.approvalFunctionality}/${userId}/approve?creatorId=${creatorId}`);
  }
  
  unApproveUsersForCreator(userId: string, creatorId: string) {
    return this.http.request<any>('delete',`${this.approvalFunctionality}/${userId}/approve?creatorId=${creatorId}`);
  }

  getCreatorTabSettings( creatorId: string ) {
    return this.http.request<any>( 'get', `${ this.settings }/creator/${ creatorId }` );
  }

  patchCreatorTabSettings( creatorId: string, payload ) {
    return this.http.request<any>( 'patch', `${ this.settings }/${ creatorId }`, payload );
  }

  postCreatorTabSettings( payload ) {
    return this.http.request<any>( 'post', `${ this.settings }`, payload );
  }

  getCreatorActivities( userId: any ) {
    return this.http.request<any>( 'get', `${ environment.API_URL + environment.API_VERSION }/CreatorActivities/user/${ userId }` );
  }

}